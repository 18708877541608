/*!

 =========================================================
 * Now UI Kit Pro - v1.2.2
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit-pro
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'kit/core/variables';
@import 'kit/pro/mixins';

// Plugins CSS
@import 'kit/core/plugins/plugin-bootstrap-switch';
@import 'kit/core/plugins/plugin-nouislider';
@import 'kit/pro/plugins/plugin-bootstrap-select';
@import 'kit/pro/plugins/plugin-tagsinput';
@import 'kit/pro/plugins/plugin-fileupload';
@import 'kit/pro/plugins/plugin-datetimepicker';

// Core CSS
@import 'core/nucleo-outline';

@import 'kit/core/alerts';
@import 'kit/core/checkboxes-radio';
@import 'kit/core/badges';
@import 'kit/core/pagination';
@import 'kit/core/typography';
@import 'kit/core/misc';

@import 'kit/core/buttons';
@import 'kit/pro/social-buttons';
@import 'kit/core/inputs';
@import 'kit/core/progress';
@import 'kit/core/pills';
@import 'kit/pro/pills-extend';

// components
@import 'kit/core/tabs';
@import 'kit/core/navbar';
@import 'kit/pro/navbar-extend';
@import 'kit/core/dropdown';
@import 'kit/pro/dropdown-extend';
@import 'kit/core/images';
@import 'kit/core/popups';
@import 'kit/pro/tables';
@import 'kit/pro/info-areas';
@import 'kit/pro/media';
@import 'kit/core/modals';
@import 'kit/pro/modals-extend';
@import 'kit/core/carousel';
@import 'kit/pro/footers';

// cards
@import 'kit/core/cards';
@import 'kit/pro/cards/card-extend';
@import 'kit/pro/cards/card-background';
@import 'kit/pro/cards/card-blog';
@import 'kit/pro/cards/card-contact';
@import 'kit/pro/cards/card-fashion';
@import 'kit/pro/cards/card-form-horizontal';
@import 'kit/pro/cards/card-no-shadow';
@import 'kit/core/cards/card-plain';
@import 'kit/pro/cards/card-pricing';
@import 'kit/pro/cards/card-profile';
@import 'kit/pro/cards/card-product';
@import 'kit/pro/cards/card-raised';
@import 'kit/core/cards/card-signup';
@import 'kit/pro/cards/card-testimonials';


// example pages and sections
@import 'kit/pro/example-pages';
@import 'kit/core/sections';
@import 'kit/pro/sections-extend';

@import 'kit/pro/responsive';
